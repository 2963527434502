import "normalize.css";
import "./PressDetailTemplate.scss";

import React, { FC, useContext, useEffect, useState } from "react";
import NavContext, {
  NavProvider,
} from "../../components/Layout/Nav/NavProvider";
import Layout from "../../components/Layout";
import SEO from "../../components/SEO";
import { t } from "i18next";
import SectionHeader from "../../components/SectionHeader";
import Slider from "../../components/Slider";
import HomeSliderContact from "../../components/HomeSliderContact";
import PressDetail from "../../components/PressDetail/PressDetail";
import { graphql } from "gatsby";
import {
  getSectionSlug,
  PageTemplateProps,
  YouplanetSections,
} from "../../constants/general";
import { gatsbyImage } from "../../components/Image/Image";
import { getCurrentLanguage } from '../../i18n';

const PressDetailPage: FC<PageTemplateProps> = ({ data, pageContext }) => {
  const { press } = data as any;
  const { isMobile } = useContext(NavContext);
  const [isMobileActive, setIsMobileActive] = useState<boolean>(false);

  const currentLanguage = getCurrentLanguage();
  const currentSlug = pageContext.slug;

  const pageTitle = `${t("press.pageTitle")} Youplanet - ${press.name}`;
  const description = `${t("press.pageDescription")} Youplanet - ${press.name}`;
  const alternativeUrls = pageContext.alternativeUrls;

  useEffect(() => {
    setIsMobileActive(isMobile());
  }, [isMobile])

  const goBackLink = () => {
    return `${getSectionSlug(YouplanetSections.PressList, currentLanguage)}`;
  };

  return data.press ? (
    <NavProvider>
      <Layout
        language={currentLanguage}
        alternativeUrls={alternativeUrls}
        isHome={false}
      >
        <SEO
          title={pageTitle}
          description={description}
          lang={currentLanguage}
          slug={currentSlug}
          imageShare={gatsbyImage(data.press.imageShare || data.press.image)}
        />
        <SectionHeader p={t("press.title")} inView={true} />
        <section className="press-detail-page">
          {isMobileActive ? (
            <>
              <PressDetail item={data.press} goBackLink={goBackLink()} />
              <HomeSliderContact />
            </>
          ) : (
            <Slider>
              <PressDetail item={data.press} goBackLink={goBackLink()} />
              <HomeSliderContact />
            </Slider>
          )}
        </section>
      </Layout>
    </NavProvider>
  ) : (
    <> </>
  );
};

export default PressDetailPage;

export const query = graphql`
  query PressDetailTemplateQuery($id: String!, $lang: String) {
    press: sanityPress(id: { eq: $id }) {
      name
      date
      slug {
        current
      }
      tags {
        name
      }
      content: _rawContent
      image {
        ...ImageFragment
      }
      imageShare {
        ...ImageFragment
      }
    }
    locales: allLocale(filter: { language: { eq: $lang } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
