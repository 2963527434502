import "./PressDetail.scss"

import React, { FC } from "react"
import { PressListItem } from "../";
import ArrowMoreContent from '../ArrowMoreContent';
export interface PressDetailProps {
  item: any;
  goBackLink: string;
}

const PressDetail: FC<PressDetailProps> = ({ item, goBackLink }) => {
  return (
    <div className='press-detail'>
      <a href={goBackLink} className="press-detail__link">
        <ArrowMoreContent
          containerHeight="2rem"
          containerWidth="16rem"
          sideWidth="1rem"
          width=".2rem"
          direction="left"
          clickHandler={null}
        />
      </a>
      <PressListItem item={item} isDetailMode hasSeoTitle />
    </div>
  )
};

export default PressDetail;
